$body-background-color: rgb(221, 207, 192);
$family-sans-serif: Calibri, sans-serif;
@import "bulma/bulma.sass";
@import "bulma-calendar";
@import "../node_modules/swiffy-slider/dist/css/swiffy-slider.css";


.mmateffu-header {
    font-family: 'Tilt Prism', fantasy;
    text-transform: uppercase;
}

.mmateffu-light-text {
    color: black;
    font-family: 'Bodoni Moda', sans-serif;
}

.mmateffu-dark-text {
    color: black;
    font-family: 'Bodoni Moda', sans-serif;
}

.container {
    max-width: 800px;
}

.carousel {
    max-width: 500px;
    padding-bottom: -10em;
    margin-bottom: -2em;
    // min-height: 1200px;
}

.mmateffu-slides {
    // max-width: 400px;
    // min-height: 600px;
    // position: relative; 
    // display: inline-block;
}

.mmateffu-slides button {
    position: absolute;
    bottom: 0;
    margin-bottom: 1em;
    left: 50%;
    transform: translate(-50%, -50%);
}